export function chunk<T>(array: T[], size: number): T[][] {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
}

export function capitalizeFirstCharacter(input: string) {
  if (input.length == 0) return input;

  return input.charAt(0).toUpperCase() + input.slice(1);
}

function getFieldsetElements(fieldset: HTMLFieldSetElement) {
  return Array.from(fieldset.childNodes)
    .filter((x) => x.nodeName == "INPUT")
    .map((x) => x as HTMLInputElement);
}

export function getRating(fieldset: HTMLFieldSetElement) {
  let values = getFieldsetElements(fieldset)
    .filter((x) => x.checked)
    .map((x) => Number(x.value));

  return values[0] || 0;
}

export function setRating(fieldset: HTMLFieldSetElement, treshold: number) {
  let elements = getFieldsetElements(fieldset);
  for (let element of elements) {
    let convertedValue = Number(element.value);
    if (!isNaN(convertedValue) && convertedValue == treshold) element.checked = true;
  }
}

export function getIdentifier() {
  return (Math.random() + 1).toString(36).substring(2);
}

export function getGuid() {
  return self.crypto.randomUUID();
}
