import { Day, RatedMeal, RatingBody } from "../entities";

const baseUrl = "https://lunchrating-service.akac.dev";

export default class ApiClient {
  apiKey: string;

  constructor(apiKey: string) {
    this.apiKey = apiKey;
  }

  async getToday() {
    let res = await fetch([baseUrl, "/api", "/today"].join(""), {
      credentials: "include",
      headers: {
        Authorization: this.apiKey
      }
    });
    if (!res.ok) throw `Failed to fetch today: ${res.status} => ${await res.text()}`;

    return (await res.json()) as Day;
  }

  async getLeaderboard(direction: string, count: number) {
    let res = await fetch([baseUrl, "/api", "/leaderboard", `?direction=${direction}`, `&count=${count}`].join(""), {
      credentials: "include",
      headers: {
        Authorization: this.apiKey
      }
    });
    if (!res.ok) throw `Failed to fetch leaderboard: ${res.status} => ${await res.text()}`;

    return (await res.json()) as RatedMeal[];
  }

  async sendRating(rating: RatingBody) {
    let res = await fetch([baseUrl, "/api", "/rate"].join(""), {
      method: "POST",
      body: JSON.stringify(rating),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.apiKey
      }
    });
    if (res.status != 202) throw `Failed to send rating ${res.status} => ${await res.text()}`;
  }
}
