import { Day, RatedMeal } from "./entities";
import * as Constants from "./constants";
import ApiClient from "./services/api";

export let apiClient = new ApiClient(Constants.apiKey);

export let today: Day;
export let ascendingLeaderboard: RatedMeal[];
export let descendingLeaderboard: RatedMeal[];

export async function loadToday() {
  today = await apiClient.getToday();
}

export async function loadLeaderboardAscending() {
  ascendingLeaderboard = await apiClient.getLeaderboard("ascending", Constants.leaderboardEntryLimit);
}

export async function loadLeaderboardDescending() {
  descendingLeaderboard = await apiClient.getLeaderboard("descending", Constants.leaderboardEntryLimit);
}
