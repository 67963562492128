import { capitalizeFirstCharacter } from "./extensions";

export const locale = "cs-CZ";

export function getCurrentDate() {
  let currentDate = new Date();

  let formattedDate = new Intl.DateTimeFormat(locale, {
    weekday: "long",
    day: "numeric",
    month: "numeric",
    year: "numeric"
  }).format(currentDate);

  return capitalizeFirstCharacter(formattedDate);
}
