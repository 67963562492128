export const mealTemplate = document.querySelector<HTMLTemplateElement>("template[name='meal']");
export const ratingFieldsetTemplate = document.querySelector<HTMLTemplateElement>("template[name='rating-fieldset']");

export const mealsContainer = document.querySelector<HTMLDivElement>(".meals-container");
export const dayCurrentDate = document.querySelector<HTMLHeadingElement>(".day-current-date");
export const dayNoMeals = document.querySelector<HTMLHeadingElement>(".day-no-meals");
export const currentRatingCount = document.querySelector<HTMLHeadingElement>(".day-current-rating-count");
export const totalRatingCount = document.querySelector<HTMLHeadingElement>(".day-total-rating-count");
export const leaderboardAscending = document.querySelector<HTMLTableElement>(".leaderboard-ascending");
export const leaderboardDescending = document.querySelector<HTMLTableElement>(".leaderboard-descending");
