import "alertifyjs/build/css/alertify.min.css";
import alertify from "alertifyjs";

import * as Site from "./site";
import * as Data from "./data";
import * as Display from "./display";
import * as Time from "./time";
import * as Storage from "./storage";

declare global {
  interface Window {
    Site: any;
    Data: any;
    Display: any;
    Time: any;
    Storage: any;
  }
}

async function main() {
  window.Site = Site;
  window.Data = Data;
  window.Display = Display;
  window.Time = Time;
  window.Storage = Storage;

  let currentDate = Time.getCurrentDate();
  Display.setCurrentDate(currentDate);

  Display.loadLastRated();

  Data.loadToday()
    .then(() => {
      console.log("[Data] Loaded today", Data.today);

      if (Data.today && Data.today.meals.length > 0) {
        Display.setCurrentRatingCount(Data.today.currentRatingCount);
        Display.setTotalRatingCount(Data.today.totalRatingCount);
        Display.addMeals(Data.today.meals);
      } else Display.addNoMealsMessage();
    })
    .catch((err) => {
      console.error("[Data] Failed to load today");
      alertify.notify(`Selhalo načítání dnešního oběda: ${err}`, "error");
      throw err;
    });

  Data.loadLeaderboardDescending()
    .then(() => {
      console.log("[Data] Loaded descending leaderboard", Data.descendingLeaderboard);

      Display.addBestRatedMeals(Data.descendingLeaderboard);
    })
    .catch((err) => {
      console.error("[Data] Failed to load descending leaderboard");
      alertify.notify(`Selhalo načítání sestupujícího žebříčku: ${err}`, "error");
      throw err;
    });

  Data.loadLeaderboardAscending()
    .then(() => {
      console.log("[Data] Loaded asencding leaderboard", Data.ascendingLeaderboard);

      Display.addWorstRatedMeals(Data.ascendingLeaderboard);
    })
    .catch((err) => {
      console.error("[Data] Failed to load asencding leaderboard");
      alertify.notify(`Selhalo načítání vzestupujícího žebříčku: ${err}`, "error");
      throw err;
    });

  let urlParameters = new URLSearchParams(window.location.search);
  if (urlParameters.has("profile")) {
    let profile = urlParameters.get("profile");

    Storage.setProfile(profile);
    alertify.notify(`Set profile to ${profile}`, "success");
  }
}

main();
