import { getGuid } from "./extensions";
import { LastRated, RatingBody } from "./entities";

export function getProfile() {
  let profile = localStorage.getItem("profile");
  if (!profile) {
    profile = getGuid();
    setProfile(profile);
  }

  return profile;
}

export function setProfile(value: string) {
  localStorage.setItem("profile", value);
}

export function getLastRated() {
  let rawLastRated: string = localStorage.getItem("lastRated");

  let lastRated = JSON.parse(rawLastRated) as LastRated;
  if (!lastRated) {
    lastRated = {
      rawDate: null,
      ratings: []
    };
  }

  return lastRated;
}

export function setLastRated(rawDate: string, rating: RatingBody) {
  let lastRated = getLastRated();
  if (!lastRated || lastRated?.rawDate != rawDate) {
    lastRated = {
      rawDate: rawDate,
      ratings: [rating]
    };
  } else {
    lastRated.ratings.push(rating);
  }

  localStorage.setItem("lastRated", JSON.stringify(lastRated));
}
