import alertify from "alertifyjs";
import { getRating } from "./extensions";
import { Meal, RatingBody } from "./entities";
import * as Data from "./data";
import * as Storage from "./storage";
import * as Display from "./display";

export async function mealRatingFormSubmitted(meal: Meal, mealElement: HTMLDivElement, formElement: HTMLFormElement) {
  let fieldset = formElement.querySelector("fieldset");
  let rating = getRating(fieldset);

  if (rating == 0) {
    return alertify.notify("Vyber úroveň hodnocení", "error");
  }

  console.log("[Events]", `Submitting rating for meal '${meal.name}' with rating '${rating}'`);

  let submitButton = formElement.querySelector<HTMLInputElement>("input[type='submit']");
  submitButton.disabled = true;

  let ratingBody: RatingBody = {
    profile: Storage.getProfile(),
    mealName: meal.name,
    score: rating
  };

  try {
    await Data.apiClient.sendRating(ratingBody);
  } catch (err) {
    console.error("[Events]", "Failed to submit rating");
    alertify.alert(`Nepodařio se odeslat hodnocení (${err})`, "error");

    throw err;
  }

  Storage.setLastRated(meal.rawDate, ratingBody);

  Data.today.currentRatingCount++;
  Display.setCurrentRatingCount(Data.today.currentRatingCount);

  Data.today.totalRatingCount++;
  Display.setTotalRatingCount(Data.today.totalRatingCount);

  alertify.notify("Hodnocení úspěšně odesláno", "success");
  mealElement.setAttribute("finished", "true");
}
