import { LastRated, RatedMeal } from "./entities";
import { chunk, getIdentifier, setRating } from "./extensions";
import * as Site from "./site";
import * as Events from "./events";
import * as Storage from "./storage";

let lastRated: LastRated;

export function loadLastRated() {
  lastRated = Storage.getLastRated();
}

export function setCurrentDate(currentDate: string) {
  Site.dayCurrentDate.textContent = currentDate;
}

export function setCurrentRatingCount(count: number) {
  Site.currentRatingCount.innerHTML = `Dnes odeslaná hodnocení: <b>${count}</b>`;
}

export function setTotalRatingCount(count: number) {
  Site.totalRatingCount.innerHTML = `Celkem odeslaná hodnocení: <b>${count}</b>`;
}

export function setMealName(mealElement: HTMLDivElement, name: string) {
  mealElement.querySelector(".meal-name").textContent = name;
}

export function setCurrentRating(mealElement: HTMLDivElement, score: number) {
  let mealCurrentRatingContainer = mealElement.querySelector<HTMLDivElement>(".meal-current-rating-container");
  let mealCurrentRating = mealElement.querySelector<HTMLDivElement>(".meal-current-rating");

  mealCurrentRatingContainer.classList.remove("hidden");
  mealCurrentRating.textContent = score.toString();
}

export function addNoMealsMessage() {
  Site.dayNoMeals.classList.remove("hidden");
}

export function addMeals(meals: RatedMeal[]) {
  for (let meal of meals) addMeal(meal);
}
export function addMeal(meal: RatedMeal) {
  let mealClone = (Site.mealTemplate.content.cloneNode(true) as HTMLTemplateElement).firstElementChild as HTMLDivElement;

  setMealName(mealClone, meal.meta.name);
  if (meal.score && meal.score > 0) setCurrentRating(mealClone, meal.score);

  let mealRatingForm = mealClone.querySelector<HTMLFormElement>(".meal-rating-form");
  let ratingFieldsetClone = (Site.ratingFieldsetTemplate.content.cloneNode(true) as HTMLTemplateElement).firstElementChild as HTMLFieldSetElement;

  let children = Array.from(ratingFieldsetClone.querySelectorAll<HTMLElement>("input, label"));
  let chunkedChildren = chunk(children, 2);
  for (let pair of chunkedChildren) {
    let identifier = getIdentifier();
    pair[0].setAttribute("id", identifier);
    pair[1].setAttribute("for", identifier);
  }

  mealRatingForm.prepend(ratingFieldsetClone);

  mealRatingForm.onsubmit = async (evt: SubmitEvent) => {
    evt.preventDefault();

    Events.mealRatingFormSubmitted(meal.meta, mealClone, mealRatingForm);
  };

  let lastRating = lastRated.ratings.find((x) => x.mealName == meal.meta.name);
  if (lastRated.rawDate == meal.meta.rawDate && lastRating) {
    mealClone.setAttribute("finished", "true");

    let submitButton = mealRatingForm.querySelector<HTMLInputElement>("input[type='submit']");
    submitButton.disabled = true;

    let fieldsetElement = mealRatingForm.querySelector<HTMLFieldSetElement>(".rating");
    setRating(fieldsetElement, lastRating.score);
  }

  Site.mealsContainer.append(mealClone);
}

export function addBestRatedMeals(ratedMeals: RatedMeal[]) {
  for (let ratedMeal of ratedMeals) addBestRatedMeal(ratedMeal);
}
export function addBestRatedMeal(ratedMeal: RatedMeal) {
  let tableBody = Site.leaderboardDescending.querySelector("tbody");
  addRatedMeal(ratedMeal, tableBody);
}

export function addWorstRatedMeals(ratedMeals: RatedMeal[]) {
  for (let ratedMeal of ratedMeals) addWorstRatedMeal(ratedMeal);
}
export function addWorstRatedMeal(ratedMeal: RatedMeal) {
  let tableBody = Site.leaderboardAscending.querySelector("tbody");
  addRatedMeal(ratedMeal, tableBody);
}

export function addRatedMeal(ratedMeal: RatedMeal, tableBody: HTMLElement) {
  let tableRow = document.createElement("tr");

  let tableDataName = document.createElement("td");
  tableDataName.textContent = ratedMeal.meta.name;

  let tableDataRating = document.createElement("td");
  tableDataRating.textContent = ratedMeal.score.toLocaleString();

  tableRow.append(tableDataName);
  tableRow.append(tableDataRating);

  tableBody.append(tableRow);
}
